import React, { FC, useContext, useState } from "react";
import { Context } from "src/common";

import { Wrapper } from "./Banner.style";
import BannerLink from "./BannerLink";
import BannerModal from "./BannerModal";

const Banner: FC = () => {
  const props = useContext(Context);
  const email = props?.CompanyInformationPublic?.email;
  const contactSlug = props.PageContact?.slug;

  const [isShowModal, setShowModal] = useState(false);
  return (
    <>
      <Wrapper>
        {contactSlug && <BannerLink href={`/${contactSlug}`} className={"phone"} icon="">
          <span>FRAGEN?<br />RUFEN SIE AN</span>
        </BannerLink>}
        <BannerLink className={"opening"} icon="" onClick={() => setShowModal(true)}>
          <span>Öffnungs-<br />zeiten</span>
        </BannerLink>
        {email && <BannerLink href={"mailto:" + email} className={"at"} icon="">
          <span>KONTAKTIEREN<br />SIE UNS</span>
        </BannerLink>}
      </Wrapper>
      {isShowModal && (
        <BannerModal onSetShow={setShowModal} companyInformation={props?.CompanyInformationPublic}/>
      )}
    </>
  );
};

export default Banner;
