import React, { FC } from "react";
import { ICompanyInformationPublic } from "src/common";
import RichTextGeneral from "../RichText";
import { BannerModalOverlay } from "./Banner.style";

const BannerModal: FC<{ onSetShow: (boolean) => void; companyInformation: ICompanyInformationPublic }> = ({ onSetShow, companyInformation }) => (
  <BannerModalOverlay onClick={() => onSetShow(false) }>
    <div>
      <h2>Öffnungszeiten:</h2>
      <hr />
      <RichTextGeneral textContent={companyInformation.openingHours}/>
      <hr />
      <p>Gerne vereinbaren wir auch individuell abweichende Termine.</p>
      <p>Fragen Sie bitte einfach mal nach!</p>
    </div>
  </BannerModalOverlay>
);

export default BannerModal;
