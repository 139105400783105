import * as React from "react";
import { FC } from "react";

import SLink from "./BannerLink.style";

const BannerLink: FC<{
  href?: string | undefined;
  className: string;
  icon?: string;
  onClick?: () => void;
}> = (props) => (
  <SLink
    href={props.href}
    isVisible={!!props.href || !!props.onClick}
    className={props.className}
    onClick={props.onClick}
  >
    { props.children }
  </SLink>
);
export default BannerLink;
