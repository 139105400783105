import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_GOLD, MIN_PLATINUM, MIN_DIAMOND } from "src/common/constants/mediaquerys";

interface Link {
  isVisible: boolean;
}

const Default = css`
  display: ${(props: Link) => props.isVisible ? "flex" : "none"};
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 50px;
  height: 50px;
  background-image: url("/images/icon-sprite-mobile.png");
  background-repeat: no-repeat;

  &.phone {
    background-color: rgba(255,255,255,.75);
  }
  &.opening {
    background-color: rgba(255,255,255,.75);
    background-image: url("/images/icon-clock-xs.png");
    background-position: center;
  }
  &.at {
    background-color: rgba(255,255,255,.75);
    background-position: 0 -48px;
  }
  &.fb {
  }

  img, span {
    display: none;
  }
`;

const Silver = css`
  width: 164px;
  height: 164px;
  padding: 10px;
  text-align: center;
  justify-content: flex-end;

  img, span {
    display: block;
  }

  span {
    color: ${props => props.theme.palette.white};
    font-size: 17px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 26px;
  }

  &.phone {
    background-image: url(/images/phone-icon.png);
    background-position: center 0px;
    background-color: rgba(129,199,220,.75);
  }
  &.opening {
    background-image: url(/images/clock-icon.png);
    background-position: center 25px;
    background-color: rgba(0,98,138,.75);
  }
  &.at {
    background-image: url(/images/mail-icon.png);
    background-position: center 0px;
    background-color: rgba(87,87,86,.75);
  }
  &.fb {
  }
`;

const Gold = css`
`;

const Platinum = css``;

const Diamond = css``;

const SLink = styled.a`
  ${Default}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
export default SLink;
