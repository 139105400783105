import * as React from "react";
import { FunctionComponent } from "react";

import SLink from "./BannerLink.style";

interface IProps {
  href: string | undefined;
  className: string;
}

const BannerLink: FunctionComponent<IProps> = props => <SLink href={props.href} isVisible={!!props.href} className={props.className}/>;
export default BannerLink;
