import styled from "styled-components";
import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Wrapper: any = styled.div`
  align-items: center;
  width: 50px;
  position: fixed;
  // z-index: 9999;
  top: 215px;
  right: 0;
  box-sizing: content-box;

  @media (${BREAKPOINTS_DM.silver_768}) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 164px;

    top: 205px;
    height: initial;
  }

  a {
    color: ${props => props.theme.palette.white};
  }

  @media (${BREAKPOINTS_DM.gold_1024}) and (-ms-high-contrast: none), (-ms-high-contrast: active){
    min-height: 150px;
  }
`;

export const BannerModalOverlay: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary}DD;

  > div {
    width: 80%;
    @media (${BREAKPOINTS_DM.silver_768}) {
      width: 350px;
    }

    h2 {
      font-size: 36px;
      margin-bottom: 72px;
    }

    * {
      color: ${({ theme }) => theme.palette.white};
      font-size: 22px;
      font-family: "Roboto Slab";
    }

    hr {
      background-color: ${({ theme }) => theme.palette.white};
      width: 100%;
      height: 1px;
      border: none;
      margin: 20px 0;
    }
  }


  :after {
    display: block;
    content: "";
    background-image: url("/images/icon-close.png");
    background-size: contain;
    position: absolute;
    top: 90px;
    right: 10px;
    width: 30px;
    height: 30px;

    @media (${BREAKPOINTS_DM.silver_768}) {
      top: 50px;
      right: 50px;
      width: 60px;
      height: 60px;
    }

  }
`;